import { createStore } from 'vuex';
import * as auth from './modules/auth';
import axios from 'axios';
import api from './modules/api';

export default createStore({
  state: {
    submitted: false,
    data: []
  },
  getters: {},
  mutations: {
    SET_STATE (state, { key, value }) {
      state[key] = value;
    }
  },
  actions: {
    async get_skills ({ commit }, form) {
      try {
        const { data } = await axios.get(api.skills);
        commit('SET_STATE', {key: 'data', value: data})
      } catch (err) {}
    },
    async save_form ({ commit }, form) {
      try {
        const url = api.form;
        form.createdAt = new Date().getTime();
        const { data } = await axios.post(url, form);
        localStorage.removeItem('data')
        commit('SET_STATE', { key: 'submitted', value: true });
      } catch (err) {
        console.log(err);
      }
    }
  },
  modules: {
    auth
  }
});
