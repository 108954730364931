import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

const routes = [
  {
    path: '/',
    redirect: (to) => {
      return { path: `/form/${to.params.email}` };
    }
  },
  {
    path: '/form/:email',
    name: 'form',
    component: () => import('../views/SkillsForm.vue')
  },
  {
    path: '/skills-view-ts',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('../views/404.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async ({ params, meta, name: route }, from, next) => {
  if (route === 'login') {
    return next();
  }
  meta?.auth && !store.state.auth.user?.name
    ? await store.dispatch('get_user', next)
    : next();
});

export default router;
